import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { setActivityFlyoutOpen, setFlyoutOpen } from '../../store/app.slice';
import { Flyout, FlyoutItem, MenuBar, Button, Div } from '../../framework';
import { RootState } from '../../store/store';
import {
  AUTH_ROUTES,
  HIRER_ROUTES,
  TALENT_ROUTES,
} from 'client/src/route/constant';
import ActivityFlyout from '../ActivityFlyout/ActivityFlyout';
import { featureToggle } from '../../../config/app.config';

type Props = {
  isMobile?: boolean;
};

const Menu: FunctionComponent<Props> = ({ isMobile }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isTalentLoggedIn, setIsTalentLoggedIn] = useState(false);
  const [isHirerLoggedIn, setIsHirerLoggedIn] = useState(false);
  const { isMenuShrank, isMenuFlyoutOpen } = useSelector(
    (state: RootState) => state.app
  );
  const { authUser, authHirer, isTalentOnboarded, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const { companyProfile } = useSelector((state: RootState) => state.hirer);

  const { impersonateId, impersonateKey } = impersonator;
  const isImpersonator = impersonateId && impersonateKey;

  const toggleMenuOpen = (isOpen: boolean) => {
    dispatch(setFlyoutOpen({ isOpen }));
  };
  const toggleActivityOpen = (isOpen: boolean) => {
    dispatch(setActivityFlyoutOpen({ isOpen }));
  };

  useEffect(() => {
    if (!isTalentLoggedIn && (authUser.id || isImpersonator)) {
      setIsTalentLoggedIn(true);
    } else if (isTalentLoggedIn && !authUser.id && !isImpersonator) {
      setIsTalentLoggedIn(false);
    }
  }, [authUser, isImpersonator]);

  useEffect(() => {
    if (authHirer.email && !isHirerLoggedIn) {
      setIsHirerLoggedIn(true);
    } else if (!authHirer.email && isHirerLoggedIn) {
      setIsHirerLoggedIn(false);
    }
  }, [authHirer]);

  const menuFlyoutFooter = (
    <Div noGrow style={{ height: '70px' }}>
      <Div pb='xs' flex style={{ textAlign: 'center' }}>
        <Div>
          <Link to='/faq'>FAQ</Link>
        </Div>
        <Div>
          <Link to='/site-policy/terms'>Terms</Link>
        </Div>
        <Div>
          <Link to='/site-policy/privacy'>Privacy</Link>
        </Div>
        <Div>
          <Link to='/about-us'>About Us</Link>
        </Div>
      </Div>
      <Div pr='l' style={{ textAlign: 'right' }}>
        Heycast.me ©{new Date().getFullYear()}
      </Div>
    </Div>
  );

  const hamburgerMenuFlyout = (
    <Flyout isOpen={isMenuFlyoutOpen} onClose={() => toggleMenuOpen(false)}>
      <Div>
        <FlyoutItem to='/'>Home</FlyoutItem>
        <FlyoutItem to={AUTH_ROUTES.TALENT_LOGIN}>Login as Talent</FlyoutItem>
        <FlyoutItem to={HIRER_ROUTES.LOGIN}>Login as Hirer</FlyoutItem>
        <FlyoutItem to='/auth/talent/register'>
          Create Talent Profile
        </FlyoutItem>
        <FlyoutItem to='/hirer/project'>Create Job</FlyoutItem>
        <FlyoutItem to='/about-us'>About Us</FlyoutItem>
      </Div>
      {menuFlyoutFooter}
    </Flyout>
  );

  // for logged in talent that has already completed the onboarding
  const profileMenuFlyout = (
    <Flyout isOpen={isMenuFlyoutOpen} onClose={() => toggleMenuOpen(false)}>
      {isTalentOnboarded && (
        <Button
          block
          rounded
          to={`/t/${isImpersonator ? impersonateId : authUser.username}`}
          ph='m'
          pb='m'
          style={{ flexGrow: 0 }}
        >
          View My Profile
        </Button>
      )}
      <Div>
        <FlyoutItem to='/talent/jobs'>Jobs</FlyoutItem>
        <FlyoutItem
          to={isTalentOnboarded ? '/talent/profile' : '/talent/profile/contact'}
        >
          Update Profile
        </FlyoutItem>
        {featureToggle.rating && (
          <FlyoutItem to='/talent/ratings'>My Ratings</FlyoutItem>
        )}
        <FlyoutItem to='/talent/settings'>Settings</FlyoutItem>
        <FlyoutItem to='/talent/change-password'>Change Password</FlyoutItem>
        <FlyoutItem to={TALENT_ROUTES.AGENDA}>My Agenda</FlyoutItem>
        <FlyoutItem to='/auth/talent/logout'>Logout</FlyoutItem>
      </Div>
      {menuFlyoutFooter}
    </Flyout>
  );

  const hirerMenuFlyout = (
    <Flyout isOpen={isMenuFlyoutOpen} onClose={() => toggleMenuOpen(false)}>
      <Div>
        {companyProfile.companyName && (
          <>
            <FlyoutItem to={HIRER_ROUTES.PROFILE}>
              My Company Account
            </FlyoutItem>
            <FlyoutItem to={HIRER_ROUTES.DASHBOARD}>My Projects</FlyoutItem>
            <FlyoutItem to={HIRER_ROUTES.SCHEDULE_LISTING}>
              Manage Schedules
            </FlyoutItem>
            <FlyoutItem to={HIRER_ROUTES.AGENDA}>Agenda</FlyoutItem>
            <FlyoutItem to={HIRER_ROUTES.LOGOUT}>Logout</FlyoutItem>
          </>
        )}
      </Div>
      {menuFlyoutFooter}
    </Flyout>
  );

  const getLogoRedirectUrl = () => {
    if (isTalentLoggedIn) return '/talent/jobs';
    else if (isHirerLoggedIn) return HIRER_ROUTES.DASHBOARD;
    return '/';
  };

  const getMenuFlyout = () => {
    if (
      (isTalentLoggedIn || isImpersonator) &&
      !location.pathname.startsWith('/hirer')
    )
      return profileMenuFlyout;
    else if (isHirerLoggedIn) return hirerMenuFlyout;
    return hamburgerMenuFlyout;
  };

  return (
    <>
      <MenuBar
        logoRedirectUrl={getLogoRedirectUrl()}
        isShrank={isMenuShrank}
        onMenuClick={() => toggleMenuOpen(true)}
        onNotificationClick={() => toggleActivityOpen(true)}
        isOnboarded={isTalentOnboarded}
        isTalentLoggedIn={isTalentLoggedIn}
        isHirerLoggedIn={isHirerLoggedIn}
        isMobile={isMobile}
      />
      {getMenuFlyout()}
      {(isTalentLoggedIn || isImpersonator) && (
        <ActivityFlyout onFlyoutClose={() => toggleActivityOpen(false)} />
      )}
    </>
  );
};

export default Menu;
